import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";

const BlogPost = styled.div`
  margin: 1rem;
`;

const PostTitle = styled.h1``;
const PostDate = styled.div`
  font-size: 16px;
`;
const PostContent = styled.main`
  a {
    text-decoration: underline;
    color: black;
    &:visited {
      text-decoration: underline;
      color: inherit;
    }
  }
`;
const PostFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0.5rem 0.5rem;
`;

export default function Template({ data, pageContext }) {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <Helmet title={`Ephemeral Persistence - ${post.frontmatter.title}`} />
      <BlogPost>
        <PostTitle>{post.frontmatter.title}</PostTitle>
        <PostDate>Posted on {post.frontmatter.date}</PostDate>
        <PostContent dangerouslySetInnerHTML={{ __html: post.html }} />
        <PostFooter>
          <div>
            {pageContext.newer && (
              <Link to={pageContext.newer.path}>{pageContext.newer.title}</Link>
            )}
          </div>
          <div>
            {pageContext.older && (
              <Link to={pageContext.older.path}>{pageContext.older.title}</Link>
            )}
          </div>
        </PostFooter>
      </BlogPost>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
